<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb class="font-weight-bold">
              {{ getOperative() }}
            </b-breadcrumb>
          </div>
          <!-- <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div> -->
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col />
  </b-row>
</template>

<script>
import {
  BBreadcrumb, /* BBreadcrumbItem, */ BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'

export default {
  name: 'SclBreadCrumb',
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    // BBreadcrumbItem,
    BRow,
    BCol,
  },
  computed: {
    ...mapGetters({ applicationInfo: 'applicants/getCurrentApplication' }),
    ...mapGetters({ operativeInfo: 'app/getCurrentOperative' }),
  },
  methods: {
    getOperative() {
      if (this.operativeInfo !== undefined && this.$route.meta.pageTitle === 'Operative') {
        return `${this.operativeInfo.firstname} ${this.operativeInfo.lastname}`
      }

      if (this.applicationInfo !== undefined && this.$route.meta.pageTitle === 'Application') {
        return `${this.applicationInfo.firstname} ${this.applicationInfo.lastname}`
      }

      return ''
    },
  },
}
</script>
